
































































import { Reports, User, Global } from '@/store';
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

@Component({})
export default class DevicesListTable extends Vue {
    @Global.State('readonly_user') readonly_user;
    @Global.State('lang') lang;
    @Reports.Getter('commissionedFixtures') commissionedFixtures;
    @User.State('project') project;
    
    @Prop() selectedDevices;

    search = '';
    
    get headers() {
        return [
            { text: 'Device ID', sortable: false, class: [{'text-xs-left': this.lang === 'en'}, {'text-xs-right': this.lang !== 'en'}], value: 'id' },
            { text: 'Name', sortable: false, class: [{'text-xs-left': this.lang === 'en'}, {'text-xs-right': this.lang !== 'en'}], value: 'name' },
            { text: 'Pole Number', sortable: false, class: [{'text-xs-left': this.lang === 'en'}, {'text-xs-right': this.lang !== 'en'}], value: 'poleNumber' },
            { text: 'Location', sortable: false, class: [{'text-xs-left': this.lang === 'en'}, {'text-xs-right': this.lang !== 'en'}], value: 'location' },
            { text: 'Status', sortable: false, class: [{'text-xs-left': this.lang === 'en'}, {'text-xs-right': this.lang !== 'en'}], value: 'status' }
        ];
    }

    get items(){
        return this.commissionedFixtures.map((device) => this.generateRowData(device));
    }

    generateRowData(device){
        const coords = device['meta.location'];
        const metaDevice = device['meta.device'];
        const rowData = {
            id: device.id,
            name: device.name,
            poleNumber: metaDevice.pole_number || '-',
            location: `(${coords.lat.toFixed(3)} , ${coords.lng.toFixed(3)})`,
            status: this.getStatusText(device.sys___active)
        };

        return rowData;
    }

    getStatusText(sys_active){
        return sys_active === 1 
            ? 'Connected'
            : sys_active === -9
                ? 'Fault In Care'
                : sys_active === -1
                    ? 'Maintenance'
                    : sys_active === 0
                        ? 'Disconnected'
                        : sys_active === -3
                            ? 'In Installation'
                            : 'Unknown';
    }
    
    updateSelected(selected, item){
        selected = !selected; 
        const newSelected = selected ? this.selectedDevices.concat([item]) : this.removeItem(item);
        this.$emit('selectedChanged', newSelected);
    }

    removeItem(item){
        const index = this.selectedDevices.indexOf(item);
        return this.selectedDevices.slice(0, index).concat(this.selectedDevices.slice(index + 1));
    }

    toggleAll(){
        const newSelected = this.selectedDevices.length === this.items.length ? [] : this.items.slice();
        this.$emit('selectedChanged', newSelected);
    }
    
}
